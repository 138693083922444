<template>
  <div class="date-picker-container">
    <label class="date-picker-field" for="start-date">
      <span class="date-picker-label" v-t="'page.trip_request.trip_details.arrival_date.label'" />
      <div class="date-picker-input-wrapper">
        <i class="bx bx-calendar"></i>
        <input id="start-date" :class="[showErrMsg ? 'border-red-base' : 'border-grey-dark', 'input']"
          :data-dd-opt-min-date="getCurrentDate"
          :placeholder="$t('page.trip_request.trip_details.arrival_date.placeholder')" :value="startDate"
          class="date-picker-input" name="start-date" type="text" v-bind="$attrs" @blur="$emit('blur')" />
      </div>
    </label>
    <label class="date-picker-field" for="return-date">
      <span class="date-picker-label" v-t="'page.trip_request.trip_details.departure_date.label'" />
      <div class="date-picker-input-wrapper">
        <i class="bx bx-calendar"></i>
        <input id="return-date" :class="[showErrMsg ? 'border-red-base' : 'border-grey-dark', 'input']"
          :data-dd-opt-min-date="getCurrentDate"
          :placeholder="$t('page.trip_request.trip_details.departure_date.placeholder')" :value="endDate"
          class="date-picker-input" name="return-date" type="text" v-bind="$attrs" @blur="$emit('blur')" />
      </div>
    </label>
  </div>
</template>

<script>
import "@/plugins/datedropper/datedropper-javascript";
const lang = localStorage.getItem("lang") || "en";
export default {
  name: "DateRangePicker",
  data() {
    return {
      startDate: "",
      endDate: "",
    };
  },
  props: {
    start: {
      type: String,
    },
    format: {
      type: String,
      default: "W, dd M y",
    },
    end: {
      type: String,
    },
    showErrMsg: {
      type: Boolean,
      default: false,
    },
    minRange: {
      type: Number,
      default: 3,
    }
  },
  computed: {
    getCurrentDate() {
      let week_days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      let months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      let date = new Date();
      let week_day = week_days[date.getDay()];
      let month = months[date.getMonth()];
      let day = date.getDate();
      let year = date.getFullYear();

      return `${week_day}, ${day} ${month} ${year}`;
    },
  },
  mounted() {
    this.startDate = this.start;
    this.endDate = this.end;
    new window.dateDropper({
      selector: ".date-picker-input",
      startFromMonday: true,
      format: "W, dd M y",
      minYear: new Date().getFullYear(),
      showArrowsOnHover: false,
      expandedOnly: true,
      doubleView: true,
      overlay: true,
      range: true,
      lang: lang,
      //minRange: this.minRange,
      autoFill: false,
      onDropdownExit: (res) => {
        let startDate = res.output.a.string;
        let endDate = res.output.b.string;
        this.startDate = startDate
        this.endDate = startDate === endDate  ? null : endDate;
        let unixTimeStampStart = res.output.a.U * 1000;
        let unixTimeStampEnd = res.output.b.U * 1000;
        let output = res.output;
        let isoStringStart = `${output.a.y}-${output.a.mm}-${output.a.dd}T00:00:00.000Z`;
        let isoStringEnd = `${output.b.y}-${output.b.mm}-${output.b.dd}T00:00:00.000Z`;

        this.$emit("getDates", {
          start: this.startDate,
          end: this.endDate,
          unixTimeStampStart: unixTimeStampStart,
          unixTimeStampEnd: unixTimeStampEnd,
          isoStringStart: isoStringStart,
          isoStringEnd: isoStringEnd,
        });
      },
    });
  },
};
</script>

<style scoped>
@import "../../plugins/datedropper/datedropper.css";

.date-picker-container {
  @apply flex justify-between flex-wrap sm:flex-nowrap;
}

.date-picker-field {
  @apply w-full sm:w-calc-1/2;
}

.date-picker-field:nth-child(2) {
  @apply mt-4 sm:mt-0;
}

.date-picker-label {
  @apply block mb-2 font-semibold text-base text-black-base;
}

.date-picker-input-wrapper {
  @apply relative;
}

.date-picker-input-wrapper i {
  @apply absolute left-3 top-1/2 transform -translate-y-1/2;
}

.date-picker-input {
  @apply w-full border rounded-lg;
  @apply focus:border-teal-base focus:ring-teal-lighter focus:ring-2;
  @apply pl-10 pr-3 py-2.5;
}
</style>
