<template>
  <div>
    <nav class="navbar">
      <div class="navbar-wrapper">
        <ButtonIcon @click.native="goToPreviousPage" />
        <ProgressIndicator :currentPage="currentStep" :totalPages="totalPages" />
      </div>
    </nav>
    <div class="form-page" v-if="isMissingParams">
      <EmptyState icon-color="red" icon-class="bx bxs-error" :use-link="true"
        :btn-text="$t('empty_state.something_went_wrong.cta')" link-path="/destinations"
        :title="$t('empty_state.something_went_wrong.title')" :text="$t('empty_state.something_went_wrong.text')" />
    </div>
    <form action="" class="form" @submit.prevent="submit">
      <div class="form-page">
        <h1 class="form-page-title" v-t="'page.trip_request.trip_details.title'" />
        <SegmentedControl :btn1="btn1" :btn2="btn2" :text1="$t('page.trip_request.trip_details.tab.exact_date')"
          :text2="$t('page.trip_request.trip_details.tab.approximate_date')" @clickBtn="toggleDateFields" />
        <div v-if="!isDateFieldChecked" class="flex justify-between flex-width mt-8">
          <DateRangePicker ref="dateRange" v-model="dateRange" :disabled="loading" :end="endDate"
            :showErrMsg="$v.form.dateRange.$error || $v.form.durationIsValid.$error" :start="startDate" class="w-full"
            v-bind="$attrs" @blur="$v.form.dateRange.$touch()" @getDates="getDateRange" />
        </div>
        <ErrorMessage class="mt-2" v-if="$v.form.dateRange.$error && !$v.form.dateRange.required"
          :errorMsg="$t('trip_request.form_2.input_3.error_1')" />

        <ErrorMessage class="mt-2" v-if="$v.form.durationIsValid.$error && !$v.form.startDate.$error"
          :errorMsg="$t('trip_request.form_2.input_3.error_4')" />
        <div v-if="isDateFieldChecked" class="flex flex-wrap justify-between w-full mt-8">
          <DateMonthPicker ref="monthDate" v-model="form.monthDate" :disabled="loading" :value="monthDate"
            :showErrMsg="$v.form.monthDate.$error" class="w-full" name="month" @blur="$v.form.monthDate.$touch()"
            @getMonthDate="getMonthDate" />
          <ErrorMessage class="mt-2" v-if="$v.form.monthDate.$error && !$v.form.monthDate.required"
            :errorMsg="$t('trip_request.form_2.input_3.error_2')" />
          <div class="input-chip-fieldset">
            <label class="input-text-label" v-t="'page.trip_request.trip_details.approximate_duration.label'" />
            <div class="input-chip-group">
              <InputChip v-for="(chip, index) in chips" :key="index" :state="chip.state" :name="chip.name"
                :text="$t(chip.text)" @click.native="toggleChip(index)" />
            </div>
          </div>
          <ErrorMessage class="mt-2" v-if="$v.form.duration.$error && !$v.form.duration.required"
            :errorMsg="$t('trip_request.form_2.input_3.error_3')" />
        </div>
        <ButtonPrimary :text="$t('trip_request.button')" class="desktop-cta" />
      </div>

      <div class="bottom-nav">
        <div class="bottom-nav-wrapper">
          <ButtonPrimary :text="`${$t('trip_request.button')}`" class="block h-12 px-8 w-full" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import DateMonthPicker from "@/components/date-pickers/DateMonthPicker";
import DateRangePicker from "@/components/date-pickers/DateRangePicker";
import ButtonIcon from "@/components/buttons/ButtonIcon";
import SegmentedControl from "@/components/tabs/SegmentedControl";
import InputChip from "@/components/inputs/InputChip";
import { mapGetters, mapActions } from "vuex";
import ErrorMessage from "@/components/ErrorMessage";
const { requiredIf, sameAs } = require("vuelidate/lib/validators");
import EmptyState from "@/components/EmptyState";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export default {
  name: "trip-details",
  components: {
    InputChip,
    SegmentedControl,
    ButtonIcon,
    DateMonthPicker,
    DateRangePicker,
    ErrorMessage,
    EmptyState
  },
  data() {
    return {
      loading: false,
      btn1: "active",
      btn2: "inactive",
      isMissingParams: undefined,
      form: {
        dateRange: "",
        startDate: "",
        durationIsValid: false,
        endDate: "",
        monthDate: "",
        duration: "",
        accompaniment: "0",
      },
      chips: [
        { name: "trip-duration", text: "page.trip_request.trip_details.approximate_duration.chip.1_2_weeks", state: "inactive", value: "1-2 weeks" },
        { name: "trip-duration", text: "page.trip_request.trip_details.approximate_duration.chip.3_weeks", state: "inactive", value: "3 weeks" },
        { name: "trip-duration", text: "page.trip_request.trip_details.approximate_duration.chip.4_weeks", state: "inactive", value: "4+ weeks" },
      ],
    };
  },
  validations: {
    form: {
      monthDate: {
        required: requiredIf(function () {
          return this.isDateFieldChecked;
        }),
      },
      durationIsValid: {
        required: requiredIf(function () {
          return !this.isDateFieldChecked;
        }),
        sameAs: sameAs(function () {
          return true && !this.isDateFieldChecked;
        })
      },
      duration: {
        required: requiredIf(function () {
          return this.isDateFieldChecked;
        }),
      },
      dateRange: {
        required: requiredIf(function () {
          return !this.isDateFieldChecked;
        }),
      },
      startDate: {
        required: requiredIf(function () {
          return !this.isDateFieldChecked;
        }),
      },
      endDate: {
        required: requiredIf(function () {
          return !this.isDateFieldChecked;
        }),
      },
    },
  },
  computed: {
    ...mapGetters("request", ["step"]),
    ...mapGetters({
      durationChips: "request/durationChips",
      monthDate: "request/monthDate",
      isTripDetailsPage: "request/isTripDetailsPage",
      totalPages: "request/totalPages",
      currentStep: "request/currentStep",
      isDateFieldChecked: "request/isDateFieldChecked",
      tripDuration: "request/tripDuration",
      startDate: "request/startDate",
      endDate: "request/endDate",
      dateRange: "request/dateRange",
      isMonthDate: "request/isMonthDate",
      isRangeDate: "request/isRangeDate",
      isoStartDate: "request/isoStartDate",
      isoEndDate: "request/isoEndDate",
    }),
  },
  methods: {
    ...mapActions({
      decreasePage: "request/decreasePage",
      increasePage: "request/increasePage",
      setDateFieldCheck: "request/setDateFieldCheck",
      setIsTravelerDetailsPage: "request/setIsTravelerDetailsPage",
      setMonthDate: "request/setMonthDate",
      setTripDuration: "request/setTripDuration",
      setStartDate: "request/setStartDate",
      setIsoStartDate: "request/setIsoStartDate",
      setIsoEndDate: "request/setIsoEndDate",
      setDateObject: "request/setDateObject",
      setEndDate: "request/setEndDate",
      setDateRange: "request/setDateRange",
      setIsRangeDate: "request/setIsRangeDate",
      setIsMonthDate: "request/setIsMonthDate",
      setTripDetails: "request/setTripDetails",
      setDurationChips: "request/setDurationChips",
      setFormData: "request/setFormData",
    }),
    isDurationValid() {
      if (this.startDate && this.endDate) {
        let startDate = dayjs.utc(this.isoStartDate);
        let endDate = dayjs.utc(this.isoEndDate);
        let diff = endDate.diff(startDate, 'day');
        return diff >= 3;
      }

      console.log('is valid', false)
      return false;
    },
    removeMonthDate() {
      this.setTripDuration(null);
      this.setMonthDate(null);
      this.form.duration = null;
      this.form.monthDate = null;
    },
    removeDateRange() {
      this.setDateRange(null);
      this.setStartDate(null);
      this.setEndDate(null);
      this.form.dateRange = null;
      this.form.startDate = null;
      this.form.endDate = null;
    },
    getMonthDate(monthDate) {
      this.form.monthDate = monthDate.monthDate;
      this.setMonthDate(monthDate.monthDate);
    },
    getDateRange(date) {
      this.form.startDate = date.start;
      this.form.endDate = date.end;
      this.setIsoStartDate(date.isoStringStart);
      this.setIsoEndDate(date.isoStringEnd);
      this.setDateObject(date);
      this.setStartDate(date.start);
      this.setEndDate(date.end);
      this.setDateRange(`${date.start} - ${date.start}`);
      this.form.dateRange = `${date.start} - ${date.end}`;
    },
    resetSetDate(btn) {
      if (btn === "first") {
        this.setMonthDate("");
        this.form.monthDate = null;
        this.setTripDuration("");
        this.chips = [
          { name: "trip-duration", text: "page.trip_request.trip_details.approximate_duration.chip.1_2_weeks", state: "inactive", value: "1-2 weeks" },
          { name: "trip-duration", text: "page.trip_request.trip_details.approximate_duration.chip.3_weeks", state: "inactive", value: "3 weeks" },
          { name: "trip-duration", text: "page.trip_request.trip_details.approximate_duration.chip.4_weeks", state: "inactive", value: "4+ weeks" },
        ];

        this.setDurationChips([
          { name: "trip-duration", text: "page.trip_request.trip_details.approximate_duration.chip.1_2_weeks", state: "inactive", value: "1-2 weeks" },
          { name: "trip-duration", text: "page.trip_request.trip_details.approximate_duration.chip.3_weeks", state: "inactive", value: "3 weeks" },
          { name: "trip-duration", text: "page.trip_request.trip_details.approximate_duration.chip.4_weeks", state: "inactive", value: "4+ weeks" },
        ]);
      } else {
        this.setStartDate("");
        this.setEndDate("");
        this.setDateRange("");
        this.form.dateRange = null;
        this.form.startDate = null;
        this.form.endDate = null;
      }
    },
    toggleDateFields(btn) {
      btn === "first" ? this.setIsRangeDate(true) : this.setIsMonthDate(true);
      this.resetSetDate(btn);
      this.setDateFieldCheck(btn !== "first");
      this.$v.$reset();
    },
    routerPush(path) {
      this.$router.push({
        path: path,
        query: {
          q: this.$route.query.q,
          country: this.$route.query.country,
          lang: this.$route.query.lang,
        },
      });
    },
    goToPreviousPage() {
      this.decreasePage();
      this.routerPush("/request");
    },
    validate() {
      if (!this.isDateFieldChecked) {
        this.form.dateRange = this.dateRange;
        this.form.startDate = this.startDate;
        this.form.endDate = this.endDate;
        this.form.durationIsValid = this.isDurationValid();
      }

      if (this.isDateFieldChecked) {
        this.form.monthDate = this.monthDate;
        this.form.duration = this.tripDuration;
      }

      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        return false;
      } else {
        return true;
      }
    },
    submit() {
      if (this.validate()) {
        let data = { ...this.form };

        if (this.isDateFieldChecked === true) {
          data.date = `${this.form.monthDate}, ${this.form.duration}`;
        } else {
          data.date = `${this.form.dateRange}`;
        }
        this.setFormData(data);
        this.setIsTravelerDetailsPage(true);
        this.increasePage();
        this.routerPush("/request/traveler-details");
      }
    },
    toggleChip(index) {
      this.chips.forEach((chip) => {
        chip.state = "inactive";
      });

      let chip = this.chips[index];
      chip.state = "active";

      this.setTripDuration(chip.value);
      this.form.duration = this.tripDuration;
      this.setDurationChips(this.chips);
    },
    validateURLParams() {
      let q = this.$route.query.q;
      let country = this.$route.query.country;

      if (q === undefined || country === undefined) {
        this.isMissingParams = true;
      } else {
        this.isMissingParams = false;
      }
    }
  },
  watch: {
    "form.monthDate"(newValue) {
      this.setMonthDate(newValue);
    },
    dateRange(newValue) {
      this.setDateRange(newValue);
    },
    startDate(newValue) {
      this.setStartDate(newValue);
    },
    endDate(newValue) {
      this.setEndDate(newValue);
      this.form.durationIsValid = this.isDurationValid();
    },
    isDateFieldChecked(newValue) {
      this.setDateFieldCheck(newValue);
    },
  },
  beforeMount() {
    if (this.isTripDetailsPage !== true) {
      this.routerPush("/request");
    }
    if (this.isDateFieldChecked === true) {
      this.btn2 = "active";
      this.btn1 = "";
    } else {
      this.btn1 = "active";
      this.btn2 = "";
    }
  },
  mounted() {
    //Get month field value on page refresh or when landing on page
    if (this.durationChips.length === 3) {
      this.chips = [...this.durationChips];
    }
    if (this.isDateFieldChecked) {
      let monthDateField = this.$refs.monthDate.$el.childNodes[0];
      let currentMonthDate = this.monthDate;
      if (currentMonthDate) monthDateField.value = currentMonthDate;
    }

    if (this.dateRange) this.form.dateRange = this.dateRange;
    if (this.startDate) this.form.startDate = this.startDate;
    if (this.endDate) {
      this.form.endDate = this.endDate;
    }
    if (this.monthDate) this.form.monthDate = this.monthDate;
    if (this.tripDuration) this.form.duration = this.tripDuration;
  },
  created() {
    this.validateURLParams();
  }
};
</script>

<style scoped>
@import "../../../plugins/datedropper/datedropper.css";

.input-datedropper {
  @apply w-full px-3 py-2.5 border border-grey-dark rounded focus:border-teal-base focus:ring-teal-lighter focus:ring-2;
}

.navbar {
  @apply top-0 flex flex-wrap items-center w-full h-20 px-4 bg-white md:px-12;
}

.navbar-wrapper {
  @apply relative flex items-center justify-between w-full h-12 max-w-screen-md transform -translate-x-1/2 left-1/2;
}

.back-btn {
  @apply flex items-center p-3 text-xl duration-300 rounded focus:outline-none focus:ring-2 focus:ring-black-lighter focus:scale-95 focus:ring-offset-2 hover:text-black-base transition ease-in-out text-black-lightest;
}

.back-btn-text {
  @apply pl-3 text-sm font-semibold uppercase;
}

.form {
  @apply w-full px-4 pb-48 mt-10 md:px-12;
}

.form-page {
  @apply relative max-w-screen-sm transform -translate-x-1/2 left-1/2;
}

.form-page-title {
  @apply mb-12 font-sans text-xl font-bold sm:text-2xl text-black-base;
}

.label {
  @apply mb-2 mt-20 font-sans text-2xl font-semibold w-full;
}

.label-details {
  @apply mb-6 font-sans text-lg text-black-lightest;
}

.bottom-nav {
  @apply fixed bottom-0 left-0 w-full p-4 bg-white border-t border-grey-base sm:mt-16;
}

.button-container {
  @apply relative flex items-center justify-between max-w-screen-sm transform -translate-x-1/2 left-1/2;
}

.flex-width {
  @apply w-full;
}

.bottom-nav {
  @apply fixed bottom-0 left-0 w-full p-4 bg-white border-t border-grey-base sm:mt-16;
  @apply block sm:hidden;
}

.bottom-nav-wrapper {
  @apply relative flex items-center justify-between max-w-screen-sm transform -translate-x-1/2 left-1/2;
}

.desktop-cta {
  @apply hidden sm:block h-12 px-8 w-full mt-8;
}

.input-chip-fieldset {
  @apply mt-8 w-full;
}

.input-text-label {
  @apply mb-2 font-sans font-semibold text-base text-black-base;
  @apply block;
}

.input-chip-group {
  @apply flex items-center flex-wrap gap-y-2;
}

.input-chip-group>label:first-child,
.input-chip-group>label:nth-child(2) {
  @apply mr-2;
}
</style>