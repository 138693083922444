<template>
  <label
    :class="['input-chip-container', state]"
    :for="id"
  >
    <input :id="id" :name="name" class="input-chip" type="checkbox"/>
    <div class="input-chip-wrapper">
      <i class="bx bx-check"></i>
      <span class="input-chip-text">{{ text }}</span>
    </div>
  </label>
</template>

<script>
export default {
  name: "InputChip.vue",
  props: {
    text: {
      type: String,
      default: "Label",
    },
    name: {
      type: String,
      default: "name",
    },
    state: {
      type: String,
      default: "inactive",
    },
  },
  computed: {
    id() {
      return this._uid;
    }
  }
};
</script>

<style scoped>
.input-chip-container {
  @apply rounded-full py-1 px-3 border;
  @apply cursor-pointer inline-flex items-center;
  @apply overflow-hidden relative;
}

.input-chip {
  @apply focus:ring-transparent focus:ring-0 absolute -left-96;
  @apply h-0 w-0 overflow-hidden;
}

.input-chip-text {
  @apply text-sm sm:text-base;
}

.inactive {
  @apply text-black-lighter;
  @apply border-grey-base;
}

.active {
  @apply bg-black-base text-white;
  @apply border-black-base;
}

.inactive .bx {
  @apply hidden;
}

.input-chip-wrapper {
  @apply flex items-center;
}

.input-chip-wrapper > i {
  @apply mr-1 text-lg;
}
</style>