<template>
  <label :for="name" class="w-full">
    <span class="month-picker-label" v-t="'page.trip_request.trip_details.month.label'"/>
    <div class="month-picker-input-container">
      <i class="bx bx-calendar"></i>
      <input
          :data-dd-opt-min-date="getCurrentDate"
          :id="name"
          :name="name"
          :placeholder="$t('page.trip_request.trip_details.month.placeholder')"
          class="month-picker-input"
          type="text"
          :value="value"
          v-bind="$attrs"
          @blur="$emit('blur')"
          :class="[showErrMsg ? 'border-red-base' : 'border-grey-dark', 'input']"
      /></div>
  </label>
</template>
<script>
import "@/plugins/datedropper/datedropper-javascript";
import dayjs from "dayjs";
const lang = localStorage.getItem("lang") || "en";

export default {
  name: "DateMonthPicker",
  props: {
    name: {
      type: String,
    },
    format: {
      type: String,
    },
    value: { type: String },
    showErrMsg: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dateFormat: this.format,
      monthDate: "",
    };
  },
  computed: {
    getCurrentDate() {
      let week_days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      let months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      let date = new Date();
      let week_day = week_days[date.getDay()];
      let month = months[date.getMonth()];
      let day = date.getDate();
      let year = date.getFullYear();

      return `${week_day}, ${day} ${month} ${year}`;
    },
  },
  methods: {
    dayjs(...args) {
      dayjs(...args);
    },
  },
  mounted() {
    new window.dateDropper({
      selector: ".month-picker-input",
      startFromMonday: true,
      minYear: new Date().getFullYear(),
      format: "M y",
      showArrowsOnHover: false,
      overlay: true,
      blocks: ["m", "y"],
      expandedOnly: false,
      lang: lang,
      onDropdownExit: (res) => {
        this.monthDate = res.output.string;
        let unixTimeStamp = res.output.U * 1000;
        let isoString = new Date(unixTimeStamp).toISOString();
        this.$emit("getMonthDate", {monthDate: this.monthDate, isoString:isoString, unixTimeStamp:unixTimeStamp});
      },
    });
  },
};
</script>

<style scoped>
@import "../../plugins/datedropper/datedropper.css";
.dd__block[data-key="d"] {
  display: none !important;
}
.month-picker-label{
  @apply font-semibold text-base mb-2 inline-block;
}
.month-picker-input-container{
  @apply relative;
}

.month-picker-input-container i{
  @apply absolute left-3 top-1/2 transform -translate-y-1/2;
}
.month-picker-input {
  @apply w-full pl-10 pr-3 py-2.5 border rounded-lg focus:border-teal-base focus:ring-teal-lighter focus:ring-2;
}
</style>
